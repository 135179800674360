import { useCallback, useMemo } from 'react';

import { useRoute } from './useRoute';

export const PARAM_CACHE: ParamCache = new Map();
export type ParamCache = Map<string, QueryParam>;
export type QueryParam = { name: string; value: string };

export function useQueryParam(name: string) {
  const [{ pathname, searchParams }, setRoute] = useRoute();
  const queryParam = searchParams?.get(name);

  const param = useMemo(() => {
    if (typeof queryParam !== 'string') {
      return null;
    }

    const cachedParam = PARAM_CACHE.get(name);
    const cachedValue = cachedParam?.value;

    if (!cachedValue || String(cachedValue) !== String(queryParam)) {
      PARAM_CACHE.set(name, { name, value: queryParam });
    }

    return PARAM_CACHE.get(name) as QueryParam;
  }, [name, queryParam]);

  const setParam = useCallback(
    (newValue: string | undefined) => {
      const isSameValue = (newValue === undefined && !param) || String(newValue) === String(param?.value);

      if (isSameValue) {
        return;
      }

      const urlSearchParams = new URLSearchParams(searchParams ?? undefined);
      if (newValue === undefined) {
        urlSearchParams.delete(name);
      } else {
        urlSearchParams.set(name, newValue);
      }

      setRoute({ pathname, searchParams: urlSearchParams });
    },
    [name, param, pathname, searchParams, setRoute],
  );

  return [param, setParam] as const;
}
